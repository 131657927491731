import React from "react"
import NewsCarousel from "../components/NewsCarousel"

const NewNews = () => {
  return (
    <>
      <div>
        <NewsCarousel />
      </div>
    </>
  )
}

export default NewNews
